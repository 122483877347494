import React, { useEffect } from 'react';
import Content from './../components/Content';
import { LinkRenderer } from './../utils'

import { Message, Avatar } from './../views/styles'
import AiAvatar from './../public/fredda-2.png';
import Anon from '../public/anon.png';
import styled, { keyframes } from 'styled-components';

import Markdown from 'react-markdown'


const typing = keyframes`
  from { width: 0 }
  to { width: 100% }
`;

const blinkCaret = keyframes`
  from, to { border-color: transparent }
  50% { border-color: #000; }
`;

const TypingAnimation = styled.span`
  display: inline-block;
  overflow: hidden;
  border-right: 2px solid #000;
  white-space: pre-wrap;
  margin: 0;
  letter-spacing: .15em;
  animation: 
    ${typing} 3.5s steps(40, end),
    ${blinkCaret} .75s step-end infinite;
`;

const MessageComponent = ({ message, messageRef, index, user }) => {
    useEffect(() => {
        if (messageRef?.current) {
            messageRef.current.querySelectorAll('.content, p, h3, ul, ol, pre').forEach((element, idx) => {
                const delay = 0.2 * (idx + 1);
                element.style.setProperty('--index', `${delay}s`);
            });
        }
    }, [message]);



    return (
        <Message className={message.role} ref={messageRef}>
            <Avatar className={message.role}>
                {message.role === 'assistant' && <img src={AiAvatar} alt="Sal" />}
                {message.role === 'user' && <img src={user ? user.picture : Anon} alt="Sal" />}
            </Avatar>

            <Markdown className="markdown" components={{ a: LinkRenderer }}>
                {message?.content?.replace(/<br>/g, '\n')}
            </Markdown>

            {message.course && <Content content={message.course} />}
        </Message>
    );
};


export default MessageComponent;
