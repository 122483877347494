import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { postRequest } from '../utils';
import ChatLayoutPage from '@/components/chat/ChatLayoutPage';
// import ChatLayout from '@/components/chat/ChatLayout';

import useChatLogic from '@/hooks/useChatLogic';

const ChatPage = () => {
    const location = useLocation();
    const { initialQuestion, initialAnswer, questionId } = location.state || {};
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [user, setUser] = useState(null);
    const {
        inputValue,
        handleInputChange,
        handleKeyDown,
        sendMessage,
        setMessageLoading,
        sendFile,
        askModel,
        messageRef,
        textareaRef,
        hiddenFileInput,
        messageLoading,
        chatHistory,
        setChatHistory,
    } = useChatLogic();

    useEffect(() => {
        if (initialQuestion && initialAnswer) {
            setChatHistory([
                { role: 'user', content: initialQuestion },
                { role: 'assistant', content: initialAnswer }
            ]);
        } else if (chatHistory.length === 0) {
            setChatHistory([{
                role: 'assistant',
                content: 'Hello! How can I help you today?'
            }]);
        }
    }, []);


    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (!newMessage.trim()) return;

        const updatedMessages = [...messages, { role: 'user', content: newMessage }];
        setMessages(updatedMessages);
        setNewMessage('');

        // Send the entire conversation history to the backend
        const response = await postRequest('/chat', JSON.stringify({
            messages: updatedMessages,
            question_id: questionId
        }));

        if (response.error) {
            // Handle error
        } else {
            setMessages([...updatedMessages, { role: 'assistant', content: response.answer }]);
        }
    };

    // useEffect(() => {
    //     if (!user.id) {
    //         window.location.href = `${process.env.REACT_APP_API_URL}/login`
    //     }
    // }, [user?.id]);

    return (
        <ChatLayoutPage
            user={user}
            chatHistory={chatHistory}
            messageLoading={messageLoading}
            messageRef={messageRef}
            inputValue={inputValue}
            textareaRef={textareaRef}
            hiddenFileInput={hiddenFileInput}
            handleInputChange={handleInputChange}
            handleKeyDown={handleKeyDown}
            sendMessage={sendMessage}
            setMessageLoading={setMessageLoading}
            sendFile={sendFile}
            askModel={askModel}
            isHalfScreen={false}
        />
    );
};

export default ChatPage;