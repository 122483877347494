import styled from 'styled-components';

const AdminView = styled.div`
  position: fixed;
  display: flex;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--white);
  color: var(--black);
  overflow-y: scroll;
  flex-direction: row;
  .max-center{
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-self: center;
    align-self: center;
    p{
      padding: 50px 0;
    }
  }
  p{
    color: black;
  }
  main{
    flex-grow: 1;
    height: 100vh;
    overflow-y: scroll;
  }
`

const ChatContainer = styled.div`
  // top: 0;
  // left: ${props => props.fullScreen ? '0px' : '124px'};
  // position: fixed;
  // display: flex;
  // flex-direction: column;
  // width: 92vw;
  // height: 100vh;
  // overflow-y: scroll;
  // z-index: 9;
  // background-color: var(--bg);
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const Button = styled.button`
  background: none;
  opacity: 0.7;
  color: var(--black);
  border: none;
  padding: 16px;
  font-size: 16px;
  margin: 0;
  border-radius: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: 500;
  &.teal{
    background-color: var(--teal);
  }

  &.secondary{
    background-color: var(--grey);
    color: var(--white);
  }


  &.main{
    background-color: var(--green);
    color: var(--black);
    border-radius: 32px;
    min-width: 100px;
  }

  &.danger{
    background-color: var(--hilight);
  }

  &:hover {
    opacity: 1;
  }

  &:focus {
    outline: none;
  }

  &:active {
    transform: scale(0.98);
  }

  &.admin{
    background-color: black;
    color: white;
    opacity: 1;
    border-radius: 6px;
    padding: 12px 16px;
    &.success{
      color: white;
      background-color: green;
      border: 1px solid green;
    }
    &.danger{
      color: black;
      background-color: red;
      border: 1px solid red;
    }
    &.secondary{
      color: black;
      background-color: white;
      border: 1px solid black;
    }
  }
`;

const DropZone = styled.div`
  transition-duration: 0.3s;
  position: absolute;
  backdrop-filter: blur(10px);
  top: 0;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  flex-direction: column;
  cursor: pointer;
  > img{
    margin-bottom: 20px
  }
`;

const Chat = styled.div`
  position: absolute;
  overflow-y: scroll;
  padding: 32px;
  top: 70px;
  display: flex;
  flex-direction: column;
  width: 80%;
  left: 50%;
  height: calc(100vh - 64px);
  padding-bottom: 130px;
  &.coach{
    top: 0;
    left: 0;
    padding: 40px 65px;
    padding-top: 80px;
    flex-grow: 1;
    backdrop-filter: blur(14.5px);
    position: relative;
    width: 100%;
    height: 100vh;
    padding-bottom: 220px;
    overflow-y: scroll;
  }
  height: 80%; // prevent to overlap with chat input
  &.small{
    width: calc(100% - 500px);
  }
  &.half{
    margin: 0 20px;
    width: calc(100% - 40px - 36vw);
    left: 36vw;
  }
  &.move{
    left: 20px;
    width: 95%;
    margin-top: 30px;
  }

  .audio-actions{
    button{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin: 0 8px;
      cursor: pointer;
      transition-duration: 0.3s;

      &.answer{
        background-color: rgba(193, 245, 83, 0.1);
        &:hover{
          background-color: rgba(193, 245, 83, 0.3);
        }
      }

      &.hangup{
        &:hover{
          background-color: rgba(246, 49, 53, 0.3);
        }
        background-color: rgba(246, 49, 53, 0.1);
      }
      img{
        margin: 0;
        padding: 0;
        width: 22px;
        height: 22px;
      }
    }
  }
  .cta{
    background-color: var(--white);
    color: var(--black);
    width: auto;
    padding: 16px;
    margin: 8px 0;
    margin-left: 40px;
    opacity: 0.9;
    align-self: flex-start;
    font-size: 14px;
  }
  .empty{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2{
      margin: 32px 0
    }
    small{
      margin: 24px 0;
      opacity: 0.5
    }
  }
`
const ChatInputContainer = styled.div`
  position: absolute;
  bottom: 40px;
  width: 97%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  border-radius: 16px;
  padding: 10px 20px;
  bottom: 15px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background: rgba(255, 255, 255, 0.10);
  backdrop-filter: blur(25px);
  min-height: 131px;
  &.small{
    width: calc(95% - 500px);
    left: calc(50% - 200px);
  }
  &.coach{
    width: calc(100vw - 200px);
    transform: none;
    left: unset;
    right: 40px;
    flex-grow: 1;
    backdrop-filter: blur(14.5px);
    background-color: rgba(171, 171, 171, 0.04);
    padding: 12px;
  }
  &.half{
    left: 70%;
    width: 50vw;
  }

  &.move{
    // width: calc(100% - 100px);
    left: 20px;
    transform: unset;
    height: 100px;
    bottom: 40px;
    background: rgba(78, 97, 123, 0.8);
    backdrop-filter: blur(25px);
  }
  &.dark{
    background-color: var(--bg);
  }
`;

const ChatInput = styled.textarea`
  flex: 1;
  background: none;
  border: none;
  padding: 0 8px;
  color: var(--white);
  margin-right: 10px;
  line-height: 1.5;
  resize: none;
  padding-top: 8px;
  margin-right: 10px;
  border-radius: 16px;
  max-height: 200px;
  font-family: 'PPMori';
  margin-top: -35px;
  &::placeholder {
    color: var(--white);
    opacity: 0.4;
  }

  &:focus {
    outline: none;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  text-align: center;
  position: relative;
  background-color: var(--bg);
  padding: 24px;

  &.half{
    width: 50vw;
    border-right: 1px solid var(--grey);
  }
  &.small{
    width: 100%;
  }

  .close{
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 20px 24px;
    border-radius: 50%;
    font-weight: 600;
    color: white;
    font-size: 22px;
    img{
      margin-right: 8px;
    }
  }
  span{
    text-transform: uppercase;
    font-size: 12px;
    opacity: 0.5;
  }
  p{
    margin: 0;
    font-size: 18px;
    margin-top: 8px;
  }
`;

const Actions = styled.div`
height: auto;
z-index: 10;
margin: 16px 0;
button{
  width: auto;
  background-color: var(--grey);
  margin: 8px 0px;
  cursor: pointer;
  text-align: left;
  text-wrap: nowrap;
  border: none;
  color: var(--white);
  font-size: 16px;
  padding: 16px 24px;
  border-radius: 16px;
  opacity: 0.7;
  transition: opacity 0.3s;
  &:hover{
    opacity: 1;
  }
}
`

const Avatar = styled.div`
  width: 50px;
  height: 50px;
  object-fit: cover;
  height auto;
  position: absolute;
  left: -70px;
  top: 0;
  &.user{
    left: unset;
    right: -70px;
  }

  img{
    width: 100%;
    border: 1px solid var(--white);
    object-fit: cover;
    border-radius: 50%;
    height: 100%;
  }
`;

const Message = styled.div`
  position: relative;
  width: auto;
  max-width: 600px;
  height: auto;
  margin: 0 40px;
  height: auto;
  border-radius: 16px;
  font-size: 16px;
  line-height: 26px;
  text-align: left;

  &.collect{
      padding: 12px;
      margin: 16px 40px;
      min-width: 450px;
      border-radius: 8px 0px 8px 8px;
      background: rgba(255, 255, 255, 0.05);

  }

  .markdown hr{
    display: none;
  }
  .markdown li{
    list-style-type: none;
    list-decoration: none;
  }

  .markdown > *:not(ul, ol), .content, .markdown li {
      padding: 12px;
      font-size: 14px;
      font-weight: 500;
      line-height: 26px;
      text-decoration: none;
      margin: 8px 0;
      // border-radius: 16px;
      // background-color: #3A4E69;
      // background-color: var(--light-grey);
      height: auto;
      width: fit-content;
  }

  &.assistant {
    .markdown > *:not(ul, ol), .content, .markdown li {
          opacity: 0;
          transition: opacity 0.3s;
          animation: fade-in 0.3s ease-in-out forwards;
          animation-delay: var(--index);
          padding-top: 4px;
          padding-bottom: 4px;
      }
  }

  &.system{
    .markdown > *:not(ul, ol), .content, .markdown li {
        background-color: #6A53F5;
    }
  }
  &.user{
    align-self: end;
    .markdown > *:not(ul, ol), .content, .markdown li {
        background-color: #5A738E;
        border-radius: 16px;
        height: auto;
      }
  }

  h3{
    margin: 24px 0;
  }

  li{
    font-size: 12pt;
      margin-left: 20px;
  }

  span{
    display: block;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .status{
    position: absolute;
    z-index: 1;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    bottom: -2px;
    right: -2px;
    background-color: yellow;
    &.NEGATIVE{
        background-color: red;
    }
    &.POSITIVE{
        background-color: green;
    }
  }
`;

const Loader = styled.div`
  position: relative;
  width: 100%;

`

const Sidebar = styled.div`
position: fixed;
top: 75px;
width: 500px;
padding: 64px 32px;
z-index: 10;
height: 84%;
padding-top: 32px;
left: ${props => props.withMainSidebar ? 'unset' : '0'};
background-color: var(--bg);
border-right: 1px solid var(--grey);
overflow-y: scroll;
&.half{
  width: 35vw;
  margin-left: 24px;
  margin-top: 33px;
  border-radius: 32px;
  border: 1px solid rgba(255, 255, 255, 0.10);
  background: rgba(255, 255, 255, 0.20);
  backdrop-filter: blur(25px);
}
.submission{
  margin: 32px 0;
  padding: 32px;
  background-color: var(--grey);
  border-radius: 16px;
  display: flex;
  align-items: center;
  line-height: 1.5;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s;
  &:hover{
    opacity: 1;
  }
  img{
    border-radius: 50%;
    margin-left: 16px;
  }

}
> button{
  bottom: 48px;
  background-color: #212121;
  margin: 8px;
  cursor: pointer;
  text-align: left;
  text-wrap: nowrap;
  border: 1px solid #FFFFFF1A;
  font-size: 16px;
  color: var(--color-white);
  pading: 16px 24px;
  border-radius: 8px;
  opacity: 0.7;
  transition: opacity 0.3s;
  &:disabled{
    pointer-events: none;
    opacity: 0.2;
  }
  &:hover{
    opacity: 1;
  }
}

.markdown{
  font-weight: 400;
  ul{
    margin-left: 32px;
  }

}
`;

const Modal = styled.div`
  position: fixed;
  top: 50vh;
  left: 50vw;
  z-index: 9;
  transform: translateX(-50%) translateY(-50%);

  .body{
    width: 400px;
    height: 450px;
    padding: 24px;
    background-color: var(--bg);
    border-radius: 16px;
    color: white;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    h1{
      font-size: 16px;
      margin-bottom: 8px;
    }
  }

  .bg-click{
    position: fixed;
    width: 200vw;
    height: 200vh;
    cursor: pointer;
    top: -100vh;
    left: -100vw;
    z-index: -1;
    background-color: #424242;
    opacity: 68%;
  }
  .green{
    color: var(--green);
  }

  .uploading-progress{
    border: 1px solid #4b4b4d;
    border-radius: 12px;
    padding: 20px;
    margin: 24px 0;
    p{
      font-size: 14px;
    }
    span{
      font-size: 10px;
      font-weight: 100;
    }
    .progress{
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 100;
      font-size: 10px;
      margin-top: 12px;
    }
    progress{
      width: 100%;
      margin-right: 5%;
      color: var(--green);
      border-radius: 12px;
      overflow: hidden;
      height: 8px;
      &::-webkit-progress-value, &::-moz-progress-value{
        background-color: var(--green);

      }
    }
  }

  .stop{
    width: 100%;
    background-color: #F55353;
    color: white;
  }

  .time{
    width: 100%;
    text-align: center;
    display: block;
  }

  audio{
    width: 100%;
    margin: 0 auto;
    display: none;
  }

  @keyframes ripple {
      0% {
          transform: scale(1);
      }
      50% {
          transform: scale(1.2);
      }
      90% {
          transform: scale(0.9);
      }
  }

  .recording {
    display: flex;
    align-items: center;
    overflow: visible;
    justify-content: center;
    border-radius: 50%;
    width: 205px;
    height: 205px;
    margin: 40px auto;
    cursor: pointer;
    position: relative;
    &:not(.nh){
        &:hover{
          .ring{
            animation: ripple 1s infinite;
          }
        }

    }
    .ring{
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      &.r{
        border: 1px solid  #F55353;
      }
      &.g{
        border: 1px solid var(--green);
      }
    }

    .r1{
      width: 120px;
      height: 120px;
      opacity: 0.75;
    }
    .r2{
      width: 155px;
      height: 155px;
      opacity: 0.5;
    }
   .r3{
      width: 200px;
      height: 200px;
      opacity: 0.2;
      animation-delay: 0.5s;
    }

    img{
      width: 25px;
      height: 55px;
      box-sizing: content-box;
      margin: 0;
      border-radius: 50%;
      padding: 20px 35px;
    }

    .red, .green{
      width: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
    &.green, .green{
      background-color: var(--green);
    }

    &.red, .red{
      background-color: #F55353;
    }
  }


  &.submission{
    .body{
      left: 50vw;
      width: 575px;
      height: 90vh;
      text-align: left;
    }

    &.audio{
      display: flex;
      justify-content: space-between;
      .body{
        width: 385px;
        height: auto;
      }
    }

    &.text{
      width: 80%;
      .body{
        width: 100%;
      }
    }

    &.upload{
      .body{
        width: 385px;
        height: auto;
      }
    }
    button{
      border-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
    }
    h1{
      display: flex;
      width: 100%;
      padding-top: 8px;
      justify-content: space-between;
      .icon{
        cursor: pointer;
        pointer-events: all;
        margin: 0;
        padding: 0;
        width: 12px;
        height: 22px;
      }
    }
    textarea{

    }
  }

  img {
    background-color: var(--light);
    border-radius: 16px;
    width: 100px;
    height: 100px;
    margin-bottom: 32px;
  }

  span {
    font-size: 14px;
    color: var(--white);
    margin: 0;
    margin-bottom: 16px;
  }

  h1 {
    margin-bottom: 32px;
    font-size: 24px;
    font-weight: bold;
    width: 90%;
    color: white;
    line-height: 1;
  }

  p {
    font-size: 16px;
    margin: 2px;
    color: #D3D3D3;
  }

  button {
    margin: 8px 0;
    padding: 16px 24px;
    background-color: var(--green);
    border-radius: 8px;
    font-weight: 500;

    &.plain{
      background-color: var(--white);
      border: 1px solid var(--dark);
      color: var(--dark);
    }
  }

  div {
    display: block;
    gap: 16px;
  }
`;

const AdminHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 32px;
  flex-grow: 1;
  .icon{
    margin-right: 8px
  }
  button{
    margin-left: 16px;
  }
  .team-head{
    display: flex;
    flex-direction: column;
    .team-status{
      display: flex;
      align-items: center;
      flex-direction: row;
      img{
        width: 25px;
        height: 25px;
        border-radius: 50%;
        margin-right: 8px;
        object-fit: cover;
      }

    }
    .team-details{
      display: flex;
      min-width: 100%;
      align-items: center;
      flex-direction: row;
      margin-bottom: 32px;
      h1{
        margin: 0 24px
      }
      .logo{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
      }
      .team, .advisor{
        display: flex;
        flex-direction: row;
        margin: 0 32px;
        img{
          width: 25px;
          height: 25px;
          border-radius: 50%;
          margin-right: 8px;
          object-fit: cover;
        }
      }
      .advisor img{
        width: 35px;
        height: 35px;
      }
    }

  }
`

const AdminSidebarForm = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid var(--admin);
  border-radius: 8px;
  max-width: 600px;
  min-width: 600px;
  height: 100vh;
  overflow-y: scroll;
  .combo{
    margin: 32px 0;
    .row{ margin: 0 }
  }

  .ql-toolbar{
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
  .ql-container{
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    font-weight: 400;
  }

  .ql-container strong{
    font-weight: bold;
  }

  p{
    color: black;
  }
  .sec-header{
    padding: 16px;
    max-height: 50px;
    min-height: 50px;
    align-items: center;

    button{
      padding: 8px;
      font-size: 12px;
    }
  }
  .icon{
    background-color: black;
    pointer-events: all;
    padding: 8px;
    box-sizing: content-box;
    border-radius: 50%;
    cursor: pointer;
  }
  form{
    max-height: 100%;
    min-height: 100%;
    margin-top: 50px;
    flex-grow: 1;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    input, select, .group > textarea{
      border: 1px solid var(--admin);
      border-radius: 8px;
      padding: 12px 8px;
    }
    textarea{
      min-height: 220px;
    }

    .col{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 100%;
      >label{
        margin-bottom: 32px;
      }
      button{
        margin-top: 8px;
        margin-right: 8px;
      }

      .badge{
        position: absolute;
        top: -10px;
        left: -10px;
        z-index: 1000;
        background-color: black;
        border-radius: 50%;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        text-align: center;
        height: 20px;
      }
      .step {
        position: relative;
        border-left: 1px solid var(--admin);
        margin:  0;
        padding: 10px;
      }

      .group{
        display: flex;
        flex-direction: column;
        margin: 16px 0;
      }
    }
    .row{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 12px 0;
      img{
        border: 1px solid var(--admin);
        width: 100px;
        margin-left: 12px;
        height: 60px;
        object-fit: cover;
        border-radius: 8px;
      }
      .group{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        max-width: 100%;
        margin: 8px;
        label{
            margin-bottom: 8px;
        }
      }
    }
    .user{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 12px 0;
        p{
          flex-grow: 1;
          margin: 0 16px;
        }
        > img{
          border: 1px solid var(--admin);
          width: 40px;
          height: 40px;
          object-fit: cover;
          border-radius: 50%;
        }
      }

  }

`
const AdminTable = styled.div`
border: 1px solid var(--admin);
padding: 12px;
border-radius: 8px;
margin: 32px;
height: calc(100vh - 200px);
width: auto;
overflow: scroll;

.head{
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--admin);
  padding-bottom: 8px;
  justify-content: space-between;


  p{
    margin-right: 16px;
  }
  .status{
    min-width: 100px;
    max-width: 100px;
  }

  .advisor{
    min-width: 100px;
    max-width: 100px;
      text-align: center;
    margin: 0;
  }

  .members{
    min-width: 100px;
    max-width: 300px;
  }

  .progress{
    min-width: 100px;
    max-width: 100px;
    margin: 0 16px;
  }

  .cohort{
    min-width: 100px;
    max-width: 100px;
  }

  .date{
    min-width: 175px;
    max-width: 175px;
  }
  .stat{
    min-width: 100px;
    max-width: 100px;
  }

  .team{
    min-width: 250px;
    max-width: 250px;
    flex-grow: 1;
  }
}
`
const FileUpload = styled.div`
  border: 1px dashed #4b4b4d;
  border-radius: 16px;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  color: white;
  padding: 16px;

  p{
    font-weight: 400;
    color: white;
    width: 80%;
    font-size: 14px;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0 auto;
  }

  img{
    width:  40px;
    max-width: 40px;
    height: 40px;
    margin-bottom: 16px;
  }

  span{
    font-size: 10px;
    width: 100%;
    text-align: center;
    font-weight: 100;
  }

  &:hover {
    border-color: #8e8e93;
    cursor: pointer;
  }

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }

`;
const Sub = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--admin);
  margin: 12px 0;
  padding: 8px 0;
  .status{
    text-align: center;
    padding: 10px;
    border-radius: 8px;
    font-size: 11px;
    &.good{
      color: white;
      background-color: green;
    }
    &.not{
      color: white;
      background-color: red;
    }
  }
  span{
    background-color: var(--admin);
    border-radius: 50%;
    padding: 8px 11px;
    color: var(--white);
    font-size: 12px;
  }

  &:last-of-type{
    border-bottom: none;
  }

 .team{
    min-width: 250px;
    max-width: 250px;
    flex-grow: 1;
  }

  .date{
    margin: 0 10px;
    min-width: 175px;
    max-width: 175px;
  }

  .stat{
    margin: 0 10px;
    min-width: 100px;
    max-width: 100px;
  }
`;
const TableHeadingDiv = styled.div`
  margin: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const TableHeadingH1 = styled.h2`
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`
const NullStateTable = styled.p`
  text-align: center;
  margin-top: 20px;
  font-weight: 400;
`;

export {
  Chat,
  ChatContainer,
  ChatInputContainer,
  HiddenFileInput,
  Button,
  ChatInput,
  Sidebar,
  Modal,
  DropZone,
  Header,
  AdminHeader,
  Actions,
  Message,
  Loader,
  Avatar,
  AdminView,
  AdminSidebarForm,
  AdminTable,
  FileUpload,
  Sub,
  TableHeadingDiv,
  TableHeadingH1,
  NullStateTable,
}
