import { useState, useEffect } from 'react';
import { getRequest } from '../utils';

export function useUser() {
  const [user, setUser] = useState({ id: '', name: '', picture: '', email: '', verified: '' });
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    // const getUser = async () => {
    //   const data = await getRequest('/user');
    //   setUser(data);
    //   window.user = data;
    //   if (data?.features) {
    //     setFeatures(data.features.filter(f => f.enabled).map(f => f.name));
    //   }
    // };
    // if (!user?.id) {
    //     getUser();
    //   }
   
  }, []);


  return { user, features };
}