import React from 'react';
import {
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';

export function PackageList({ packages, user }) {
    const stripe = useStripe();
    const elements = useElements();
  
    const handlePackageSelection = (packageSelected) => {
      console.log(packageSelected);
      if (elements == null) {
        return;
      }
      const options = {
        mode: 'subscription',
        // Fully customizable with appearance API.
        successUrl: `${window.location.origin}/payment-status?payment_status=success&limit=${packageSelected?.limit}`,
        cancelUrl: `${window.location.origin}/packages?payment_status=cancel`,
        billingAddressCollection: 'auto',
        lineItems: [
          {
            price: `${packageSelected?.price_stripe_id}`,
            quantity: 1,
          },
        ],
        customerEmail: user?.email,
        clientReferenceId: `${user?.id}`,
      };
      stripe?.redirectToCheckout(options).then((tes) => {
        console.log(tes);
        alert('come back!');
      });
    };
    const paidPackages = packages?.filter((p) => p.price !== 0);
  
    return (
      // <Elements stripe={stripePromise} options={options}>
      <div className="gap-8 space-y-10 sm:block md:flex md:space-y-0 pricing-module">
        {packages &&
          packages?.map((pack) => (
            <div
              key={`${pack.id}-package-list`}
              className="flex-1 rounded-md border border-gray-200 "
            >
              <div className="px-4 py-9">
                <div className="flex justify-center gap-2">
                  <h1 className="mb-1 text-2xl font-bold">
                    {pack.price > 0 ? `$${pack.price} / Month` : `$${pack.price}`}
                  </h1>
                  {pack.price === 0 && (
                    <div className="text-sm" style={{ alignSelf: 'center' }}>
                      <span className="rounded-md bg-zinc-200 px-1">Free</span>
                    </div>
                  )}
                </div>
                <p className="mb-4 text-center text-lg">
                  {pack.question_limit > 100 ? 'Unlimited ' : pack.question_limit} &nbsp;
                  {`${pack.price !== 0 ? 'questions' : 'questions'}`}
                  {/* {pack.price !== 0 ? 'questions per month' : 'questions'} */}
                </p>
                <button
                  className="hover:text-zinc-white w-full cursor-pointer rounded-md bg-black px-3  py-2 text-sm text-white hover:bg-black hover:text-zinc-300 disabled:cursor-not-allowed  disabled:border-zinc-300 disabled:text-zinc-300 disabled:hover:bg-white disabled:hover:text-zinc-300"
                  onClick={() => handlePackageSelection(pack)}
                  disabled={user?.package?.question_limit === pack.question_limit}
                >
                  {user?.package?.question_limit === pack.question_limit
                    ? 'Current plan'
                    : 'Subscribe'}
                </button>
              </div>
            </div>
          ))}
      </div>
      // </Elements>
    );
  }
  export default PackageList;