import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { getRequest } from './../utils.js';
import Icon from './../components/Icon.js';
import Profile from './../components/Profile.js';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useUser } from '../hooks/useUser'; // Import the custom hook
import profileImage from '../public/profile-pic.png';

const navigation = [
  { name: 'Ask question', href: '/' },
  { name: 'Live help', href: '/call' },
  // { name: 'Chat', href: '/chat' },
  { name: 'My questions', href: '/my-questions' },
  { name: 'Packages', href: '/packages' },
  // { name: 'Contact us', href: '/contact' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const NavBar = () => {
  const { user, setUser, features } = useUser();
  const [profileOpen, setProfileOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleProfile = async () => {
    const data = await getRequest('/user')
    setUser(data)
    window.reloadUser()
    setProfileOpen(false)
  }

  const cancelProfile = async () => { setProfileOpen(false) };
  window.openEditUserModal = () => { setProfileOpen(true) };

  return (
    <nav className="bg-gray-800">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            >
              <span className="sr-only">Open main menu</span>
              {isOpen ? (
                <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
          
          {/* Desktop navigation */}
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex flex-shrink-0 items-center">
              <img
                alt="Your Company"
                src={"https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"}
                className="h-8 w-auto"
              />
            </div>
            <div className="hidden sm:ml-6 sm:block">
              <div className="flex space-x-4">
                {navigation.map((item) => (
                  features.includes('coaching') || item.name !== 'Coach' ? (
                    <NavLink
                      key={item.name}
                      to={item.href}
                      className={({ isActive }) =>
                        classNames(
                          isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'rounded-md px-3 py-2 text-sm font-medium'
                        )
                      }
                    >
                      <Icon name={item.icon} size={24} />
                      {item.name}
                    </NavLink>
                  ) : null
                ))}
              </div>
            </div>
            </div>
          {/* User menu */}
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <button
              className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
              onClick={() => setProfileOpen(true)}
              // onClick={() => {window.location.href = process.env.REACT_APP_API_URL + '/logout'}}
            >
              <span className="sr-only">Open user menu</span>
              <img
                className="h-8 w-8 rounded-full"
                src={profileImage} //{user ? user.picture : profileImage}
                alt=""
              />
            </button>
          </div>
          </div>
       
      </div>

      {/* Mobile navigation */}
      {isOpen && (
        <div className="sm:hidden">
          <div className="space-y-1 px-2 pb-3 pt-2">
            {navigation.map((item) => (
              features.includes('coaching') || item.name !== 'Coach' ? (
                <NavLink
                  key={item.name}
                  to={item.href}
                  className={({ isActive }) =>
                    classNames(
                      isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'block rounded-md px-3 py-2 text-base font-medium'
                    )
                  }
                >
                  <Icon name={item.icon} size={24} />
                  {item.name}
                </NavLink>
              ) : null
            ))}
          </div>
        </div>
      )}
      
      {profileOpen && <Profile cancelProfile={cancelProfile} handleProfile={handleProfile} closeModal={() => setProfileOpen(false)} />}
    </nav>
  );
};

export default NavBar;