import { useState } from "react";
import ToolTipCom from "./ToolTipCom";

export const AccordionItem = ({
    question,
    children,
    label = "Question",
    extra,
    count,
  }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {
      setIsOpen(!isOpen);
    };
    const copyTextToClipboard = () => {
      navigator.clipboard.writeText(question);
    };
    return (
      <div>
        <div
          style={{
            display: "grid",
            alignItems: "center",
            columnGap: "10px",
            justifyContent: "space-between",
            gridTemplateColumns: "auto 1fr auto",
          }}
        >
          <ToolTipCom tooltip="Copy">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
              onClick={copyTextToClipboard}
              style={{
                cursor: "pointer",
              }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
              />
            </svg>
          </ToolTipCom>
          <h2>
            <button
              type="button"
              className="flex items-center justify-between w-full py-5 font-medium text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400 gap-3"
              aria-expanded={isOpen ? "true" : "false"}
              onClick={toggle}
            >
              <span
                style={{
                  textAlign: "left",
                  fontSize: "14px",
                }}
              >
                {`${label}${` ${count || ""}`}:`} {question}
              </span>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "15px",
                }}
              >
                <p
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {extra}
                </p>
              </div>
            </button>
          </h2>
          <svg
            className={`w-3 h-3 ${isOpen ? "" : "rotate-180"} shrink-0`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
            onClick={toggle}
            style={{
              cursor: "pointer",
            }}
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </div>
  
        <div
          className={`${isOpen ? "block" : "hidden"}`}
          aria-labelledby="accordion-heading"
        >
          {children}
        </div>
      </div>
    );
  };