import React from 'react';
import { ChatContainer, Header, Chat, Loader, Avatar, Message as PreworkMessage } from '@/views/styles';
import Markdown from 'react-markdown'
import styled from 'styled-components';
import Icon from '@/components/Icon';
import Message from '@/components/Message';
import ChatInput from '@/components/chat/ChatInput';
import Poppy from '@/public/poppy.png';
import Anon from '@/public/anon.png';
import Content from '@/components/Content';
import { LinkRenderer } from '@/utils';

const StyledChatMessage = styled.p`
  color: white;
  font-size: 14px;
  font-weight: 700;
`

const ChatLayout = ({
    lesson,
    user,
    chatHistory,
    messageLoading,
    chatContainerRef,
    messageRef,
    inputValue,
    textareaRef,
    hiddenFileInput,
    handleInputChange,
    handleKeyDown,
    sendMessage,
    setMessageLoading,
    sendFile,
    askModel,
    isHalfScreen,
    children,
    onBackClick,
    isPreWork = false,
    collected,
    handleSidebarInput,
    onEnter,
    validationAttempted,
    inputErrors,
    isContentPage,
}) => {
    return (
        <ChatContainer>
            {onBackClick &&
                <Header className="small">
            <div className="close" onClick={onBackClick}>
                <Icon name="back" size="22"></Icon> {lesson?.name}
                    </div>
                </Header>
            }
            
            <Chat className={isHalfScreen ? "half" : "move"}>
                {chatHistory.map((message, index) => {
                    const content = isPreWork ? message?.content?.replace(/<br>/g, '\n') : undefined;
                    return (
                        <React.Fragment key={index + message.role}>
                            {isPreWork ?

                                <PreworkMessage
                                    message={message}
                                    index={index}
                                    user={user}
                                    messageRef={messageRef}
                                    className={isPreWork ? `${message.role} ${message.collect ? 'collect' : ''}` : undefined}
                                >
                                    {isPreWork &&
                                        <>
                                            <Avatar className={message.role}>
                                                {message.role === 'assistant' && <img src={Poppy} alt="Sal" />}
                                                {message.role === 'user' && !message.collect && <img src={user ? user.picture : Anon} alt="Sal" />}
                                            </Avatar>

                                            {message.collect ?
                                                <StyledChatMessage>{content}</StyledChatMessage>
                                                :
                                                <Markdown className="markdown" components={{ a: LinkRenderer }} >{content}</Markdown>

                                            }

                                            {message.course && <Content content={message.course} />}

                                            {message.collect && (
                                                <InputForm
                                                    message={message}
                                                    collected={collected}
                                                    handleSidebarInput={handleSidebarInput}
                                                    onEnter={onEnter}
                                                    validationAttempted={validationAttempted}
                                                    inputErrors={inputErrors}
                                                />
                                            )}
                                        </>
                                    }
                                </PreworkMessage>

                                :
                                <Message
                                    message={message}
                                    index={index}
                                    user={user}
                                    messageRef={messageRef}
                                    key={index + message.role}
                                    className={isPreWork ? `${message.role} ${message.collect ? 'collect' : ''}` : undefined}
                                />
                            }
                        </React.Fragment>
                    )
                })}
                {messageLoading && <Loader> <span className="loader"></span> </Loader>}
                {children}
            </Chat>
            {!isPreWork &&
                <ChatInput
                    lesson={lesson}
                    hiddenFileInput={hiddenFileInput}
                    textareaRef={textareaRef}
                    inputValue={inputValue}
                    handleInputChange={handleInputChange}
                    handleKeyDown={handleKeyDown}
                    sendMessage={sendMessage}
                    messageLoading={messageLoading}
                    setMessageLoading={setMessageLoading}
                    sendFile={sendFile}
                    askModel={askModel}
                    isHalfScreen={isHalfScreen}
                    isContentPage={isContentPage}
                />
            }

        </ChatContainer>
    );
};
export default ChatLayout;


const InputBar = styled.div`
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  width: 100%;

  p{
    margin: 24px 0;
    line-height: 1.5;
  }
  span{
    margin: 0;
    margin-top: -16px;
    padding: 0;
    color: var(--Error-100, #EF4444);
    text-align: right;
    font-size: 14px;
    font-weight: 400;

  }
  input{
    color: white;
    font-size: 14px;
    font-weight: 600;
    padding: 16px;
    font-size: 16px;
    margin-bottom: 16px;
    width: 100%;

    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.05);
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(25px);

    &:active, &:focus{
      border: 1px solid var(--hilight);
    }
  }
`

const InputForm = ({ message, collected, handleSidebarInput, onEnter, validationAttempted, inputErrors }) => {
    if (!message.collect) {
        return null;
    }

    return (
        <InputBar>
            {Array.from({ length: message.collect.count }, (_, index) => (
                <div key={index}>
                    <input
                        type="text"
                        value={collected[message.collect.name] ? collected[message.collect.name][index] || '' : ''}
                        onChange={(e) => handleSidebarInput(message.collect.name, index, e.target.value)}
                        onKeyDown={(e) => onEnter(e)}
                        required={message.collect.required}
                        placeholder={`Response #${index + 1}`}
                    />
                    {validationAttempted && inputErrors[message.collect.name] && inputErrors[message.collect.name][index] && (
                        <span className="error-message">{inputErrors[message.collect.name][index]}</span>
                    )}
                </div>
            ))}
        </InputBar>
    );
};