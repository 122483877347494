import React, { useState, useEffect } from 'react';
import Confetti from 'react-dom-confetti';
import { useUser } from '@/hooks/useUser';
import UsageBar from '@/components/packages/usageBar';
import PackageHeader from '@/components/packages/packageHeader';
import {
    Elements,
} from '@stripe/react-stripe-js';
import PackageList from '@/components/packages/packageList';
import { stripePromise } from '@/util/helper';
import { usePackages } from '@/hooks/usePackages';

const Packages = () => {
    const [error, setError] = useState(null);
    const { user, isLoading } = useUser();
    const { packages, loadingPackages } = usePackages();

    useEffect(() => {
        if (!user.id) {
            // window.location.href = `${process.env.REACT_APP_API_URL}/login`
        }
    }, [user?.id]);

    if (loadingPackages || isLoading) {
        return <div>Loading...</div>;
    }


    const questionsLeft = user?.questions_left < 0 ? Math.min(0, user?.questions_left) : user?.questions_left;
    const used = (user?.package?.question_limit || 0) - questionsLeft;
    const limit = user?.package?.question_limit;
    
    return (
        <div className="mx-auto flex flex-col gap-4">
            <div className="mx-auto flex flex-col gap-4">
                <main
                    className="flex flex-col justify-between items-center p-4"
                    style={{
                        marginTop: '0px',
                        paddingTop: '0px',
                    }}
                >
                    {/** Error Message */}
                    {error && (
                        <div className="rounded-md p-4">
                            <p className="text-red-500">{error}</p>
                        </div>
                    )}
                </main>
            </div>
            {/** Package list */}
            <PackageHeader />
            <div
                style={{ marginTop: '30px', marginLeft: '30px', marginRight: '30px' }}
            >
                <div className="relative mx-auto flex max-w-fit items-center space-x-2">
                    <Confetti
                        //   active={paymentStatus ? paymentStatus === 'success' : false}
                        config={{ elementCount: 200, spread: 90 }}
                    />
                </div>
                {user && user.package && (
                    <>
                        <UsageBar showRatio={false} used={used} limit={limit} />

                        <div
                            className="mb-3 flex items-baseline justify-between"
                            style={{ marginTop: '10px' }}
                        >
                            <div>
                                <h2
                                    className="font-display text-2xl font-bold tracking-wide"
                                    style={{ fontSize: '18px' }}
                                >
                                    Your usage
                                </h2>
                            </div>
                            {used !== undefined && limit === undefined && (
                                <div>
                                    <p
                                        className="font-display text-xl font-bold"
                                        style={{ fontSize: '18px' }}
                                    >
                                        Used: {used}/{limit >= 1000 ? 'Unlimited' : limit}
                                    </p>
                                </div>
                            )}
                        </div>
                    </>
                )}

                <div>
                    <div
                        className="mb-4 items-baseline justify-between"
                        style={{ marginTop: '50px' }}
                    >
                        <h2
                            className="font-display text-3xl font-bold tracking-wide"
                            style={{
                                fontSize: '20px',
                            }}
                        >
                            Simple,{' '}
                            <span className="bg-gradient-to-r from-gray-800 via-gray-500 to-gray-800 bg-clip-text text-transparent">
                                credit-based
                            </span>{' '}
                            packages
                        </h2>
                        {/* <div>
                        <p className="text-base md:text-lg">
                          Note: questions per month
                        </p>
                      </div> */}
                    </div>
                    <Elements stripe={stripePromise}>
                        <PackageList packages={packages} user={user} />
                    </Elements>
                </div>


            </div>
        </div>
    );
};

export default Packages;

