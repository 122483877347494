import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { datadogLogs } from '@datadog/browser-logs';

const PROGRAMS = ['betacamp', 'alpha-x', 'launchpad']

const PROGRAM_NAMES = {
  'BETACAMP': 'betacamp',
  'ALPHAX': 'alpha-x',
  'LAUNCHPAD': 'launchpad',
}

const getRequest = async (path) => {
  try{
    const response = await fetch(`${process.env.REACT_APP_API_URL}${path}`, {
      method: 'GET',
      credentials: 'include'
    })
    const data = await response.json()
    return data
  }catch(e){
    // datadogLogs.logger.error(`Error during ${path} get request`, {
    //   message: e.message,
    //   stack: e.stack,
    //   path: path,
    // });
  }
}

const uploadRequest = async (path, body) => {
  try{
  const response = await fetch(`${process.env.REACT_APP_API_URL}${path}`, {
      method: 'POST',
      credentials: 'include',
      body: body,
    });
    const data = await response.json();
    return data;
  }catch(e){
    // datadogLogs.logger.error(`Error during ${path} upload request`, {
    //   message: e.message,
    //   stack: e.stack,
    //   path: path,
    // });
  }
};

const uploadRequestWithProgress = (path, body, onProgress, onComplete, onError) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open('POST', `${process.env.REACT_APP_API_URL}${path}`, true);
    xhr.withCredentials = true;

    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.setRequestHeader('Access-Control-Allow-Methods', 'POST, OPTIONS');
    xhr.setRequestHeader('Access-Control-Allow-Headers', 'Content-Type');

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total);
        onProgress(percentComplete);
      }
    };

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        onComplete(JSON.parse(xhr.response));
        resolve(xhr.response);
      } else {
        onError(`Request failed with status ${xhr.status}`);
      }
    };

    xhr.onerror = () => reject(xhr.statusText);
    xhr.send(body);
  });
};

const multiModalChat = (type, body, onProgress, onStream, onComplete, onError) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open('POST', `${process.env.REACT_APP_API_URL}/multimodal/${type}`, true);
    xhr.withCredentials = true;

    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.setRequestHeader('Access-Control-Allow-Methods', 'POST, OPTIONS');
    xhr.setRequestHeader('Access-Control-Allow-Headers', 'Content-Type');

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total);
        onProgress(percentComplete);
      }
    };

    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.LOADING) {
        const text = xhr.responseText;
        onStream(text);
      }
    };

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        onComplete();
        resolve(xhr.response);
      } else {
        onError(`Request failed with status ${xhr.status}`);
      }
    };

    xhr.onerror = () => reject(xhr.statusText);

    xhr.send(body);
  });
};


const postRequest = async (path, body) => {
  try{
    const response = await fetch(`${process.env.REACT_APP_API_URL}${path}`, {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      credentials: 'include',
      body: body,
    });
    const data = await response.json();
    return data;
  }catch(e){
    // datadogLogs.logger.error(`Error during ${path} post request`, {
    //   message: e.message,
    //   stack: e.stack,
    //   path: path,
    // });
    return e;
  }
};

const deleteRequest = async (path, body) => {
  try{
    const response = await fetch(`${process.env.REACT_APP_API_URL}${path}`, {
      headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
      method: 'DELETE',
      credentials: 'include',
      body: body,
    });
    const data = await response.json();
    return data;
  }catch(e){
    // datadogLogs.logger.error(`Error during ${path} delete request`, {
    //   message: e.message,
    //   stack: e.stack,
    //   path: path,
    // });
    return e;
  }
};

const trackEvent = async (event, payload) => {
  postRequest('https://api2.amplitude.com/2/httpapi', JSON.stringify({
    "api_key": "444b92ad9559f63b753553d3f58ba823",
    "events": [
      {
        ...payload,
        "event_type": event
      }
    ]
  }))
}

const callGPT = (url, data, setter) => {
  let r = '';
  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then(response => {
    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    const processText = ({ done, value }) => {
      if (done) { return; }
      r += decoder.decode(value, { stream: true });
      setter(r);
      return reader.read().then(processText);
    };
    return reader.read().then(processText);
  })
  .catch(error => {
    console.error("Error in fetching data:", error);
  });
}

const gpt = (question, context, submission, setter, user) => {
  const url = `${process.env.REACT_APP_API_URL}/question/gpt`;
  const data = {
    prompt: context,
    query: question,
    user_id: user.id,
    submission: submission || ''
  };
  callGPT(url, data, setter);
};

const askChatQuestion = (question, context, submission, setter, user, chat_id) => {
  const url = `${process.env.REACT_APP_API_URL}/chat/question/gpt`;
  const data = {
    prompt: context,
    query: question,
    user_id: user.id,
    submission: submission || '',
    chat_id: chat_id
  };
  callGPT(url, data, setter);
};

const gptFile = (type, file, context, setter) => {
  const url = `${process.env.REACT_APP_API_URL}/multimodal/${type}`;
  const data = {
    prompt: context,
    file: file,
    user_id: window.user.id
  };
  callGPT(url, data, setter);
};

const gptFeedback = (question, context, setter) => {
  const url = `${process.env.REACT_APP_API_URL}/feedback/gpt`;
  const data = {
    prompt: context,
    query: question,
    user_id: window.user.id
  };
  callGPT(url, data, setter);
};

const gptCoach = (message, allTasks, goal, memo, currentTask, setter) => {
  const url = `${process.env.REACT_APP_API_URL}/coaching/message`;
  const data = {
    message: message,
    user_id: window.user.id,
    allTasks: allTasks,
    goal: goal,
    memo: memo,
    activeTask: currentTask,
  };
  callGPT(url, data, setter);
};

async function transcribeAudio(audioBlob) {
    const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
    const url = 'https://api.openai.com/v1/audio/transcriptions';

    const formData = new FormData();
    formData.append('file', audioBlob, 'audio.wav');
    formData.append('model', 'whisper-1');

    const headers = new Headers();
    headers.append('Authorization', `Bearer ${apiKey}`);

    const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: formData
    });

    const responseData = await response.json();
    return responseData.text || '';
}

function formatFileSize(bytes) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}

const Portal = ({ children }) => {
  const root = document.getElementById('modal-root');
  return createPortal(children, root);
};

const LinkRenderer = ({ href, children }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);

const ImgRender = ({ src, children }) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <img src={src} alt="alt" onClick={() => { setOpen(true) }} />
      {children}

      {open &&
        <div className="lightbox" >
          <div className="backdrop" onClick={() => { setOpen(false) }}></div>
          <img src={src} alt="alt"/>
        </div>
      }
    </div>
  )

}

export {
  getRequest,
  postRequest,
  uploadRequest,
  multiModalChat,
  uploadRequestWithProgress,
  trackEvent,
  gpt,
  gptCoach,
  gptFeedback,
  gptFile,
  transcribeAudio,
  PROGRAMS,
  PROGRAM_NAMES,
  LinkRenderer,
  ImgRender,
  Portal,
  formatFileSize,
  deleteRequest,
  askChatQuestion
};
