import { useState, useEffect } from 'react';
import { getRequest } from '../utils';

export function usePackages() {
    const [packages, setPackages] = useState([]);
    const [loadingPackages, setLoadingPackages] = useState();

    const loadPackages = async () => {
        setLoadingPackages(true);
        const requestData = { user_id: window.user?.id };
        const data = await getRequest('/user/packages', JSON.stringify(requestData));
      
        setPackages(data?.packages);
        
        setLoadingPackages(false);
    };

    useEffect(() => {
        loadPackages();
    }, []);

    return { packages, loadPackages, loadingPackages };
}