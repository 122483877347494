import React, { useState, useEffect } from 'react'
import LoadingDots from '@/components/ui/LoadingDots';
import AIAnswer from '@/components/ui/AIAnswer';
import { useUser } from '../hooks/useUser';
import styled from 'styled-components';
import { postRequest } from '../utils';
import { ToastContainer, toast } from 'react-toastify';
import PageLayout from '@/components/ui/PageLayout';
import { usePackages } from '@/hooks/usePackages';
import OutOfCreditMessage from '@/components/packages/OutOfCreditMessage';

const StyledTextarea = styled.textarea`
  position: relative;
  resize: none;
  font-size: 1.1rem;
  padding: 1rem 2rem 1rem 2rem;
  width: 75vw;
  border-radius: 0.5rem;
  border: 1px solid #d9d9e3;
  background: #ffffff;
  color: #000;
  outline: none;
  font-size: 16px;
`;

const AskQuestion = () => {
  const [error, setError] = useState(null);
  const { user, loading: isLoadingUser } = useUser();
  const { packages, loadingPackages } = usePackages();

  const [loadingAnswer, setLoadingAnswer] = useState(false);
  const [loadingSources, setLoadingSources] = useState(false);

  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [answerID, setAnswerID] = useState('');

  const handleQuestionChange = (e) => {
    setQuestion(e.target.value);
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    askQuestion(question);
    setError(null);
  }

  //prevent empty submissions
  const handleEnter = (e) => {
    if (e.key === "Enter" && question) {
      handleSubmit(e);
    } else if (e.key == "Enter") {
      e.preventDefault();
    }
  };

  const askQuestion = async () => {
    setLoadingAnswer(true);
    const requestData = { user_id: user.id, question };
    const data = await postRequest('/answer-question', JSON.stringify(requestData));
    if (data.error) {
      toast.error("Something went wrong, please try again later.");
    } else {
      setAnswer(data.answer);
      setAnswerID(data.question_id);
    }
    setLoadingAnswer(false);
  };
  const hasQuestionsLeft = user && user?.questions_left <= 0;

  // useEffect(() => {
  //   if (!user.id) {
  //     window.location.href = `${process.env.REACT_APP_API_URL}/login`
  //   }
  // }, [user?.id]);

  return (
    <PageLayout title="Ask your question">
      <div className="text-center">
        <div className="relative">
          <form onSubmit={handleSubmit}>
            <StyledTextarea
              disabled={
                loadingAnswer ||
                loadingSources ||
                user?.questions_left === 0
              }
              onKeyDown={handleEnter}
              autoFocus={false}
              rows={1}
              maxLength={512}
              id="userInput"
              name="userInput"
              placeholder={
                loadingAnswer || loadingSources
                  ? "Waiting for response..."
                  : "Type your question or keyword"
              }
              value={question}
              onChange={handleQuestionChange}
              className="text-sm min-w-72 disabled:opacity-50 focus:outline-none focus:border-gray-500 focus:ring-3 focus:ring-gray-400 focus:ring-opacity-50 placeholder-gray-500"

            />
            {user &&
              user?.package &&
              user?.package?.questions_left !== 1000000000 && (
                <p
                  className="text-right text-sm text-black font-medium"

                >
                  Remaining questions:{" "}
                  {Math.max(0, user?.questions_left)} of{" "}
                  {user?.package?.question_limit}
                </p>
              )}
            <button
              type="submit"
              disabled={loadingAnswer || loadingSources || user?.questions_left <= 0}
              className="absolute top-3.5 right-4 text-gray-400 bg-transparent p-1 border-none flex"
            >
              {loadingAnswer || loadingSources ? (
                <div className="absolute top-0 right-1">
                  <LoadingDots color="#000" />
                </div>
              ) : (
                // Send icon SVG in input field
                <svg
                  viewBox="0 0 20 20"
                  className="rotate-90 w-5 h-5 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                </svg>
              )}
            </button>
          </form>
          {/* <FilterSource
                  handleSourceChange={(filters) => setFilters(filters)}
                /> */}
        </div>
      </div>
      {/* AI Answer */}
      {answer || loadingAnswer ? (
        <AIAnswer
          answer={answer}
          loadingAnswer={loadingAnswer}
          answerID={answerID}
          question={question}
        />
      ) : null}
      <ToastContainer />


      {!loadingAnswer && !isLoadingUser && !loadingPackages &&
        user?.questions_left <= 0 && (
          <OutOfCreditMessage packages={packages} user={user} />

        )}
    </PageLayout>
  )
}

export default AskQuestion;