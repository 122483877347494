import React from 'react';

function UsageBar({ showRatio = true, used, limit }) {
    return (
      <div className="flex items-center gap-3">
        <div
          className="w-full overflow-hidden rounded-full bg-zinc-200"
          style={{ marginTop: '10px' }}
        >
          <div
            className="rounded-full bg-black py-1 text-center text-xs font-medium leading-none text-white"
            style={{
              width: `${limit === 0 ? 0 : Math.ceil((used / limit) * 100)}%`,
              backgroundColor: '#5046E4',
            }}
          >
            <span className="p-1">{`${
              limit == 0 ? 0 : Math.ceil((used / limit) * 100)
            }%`}</span>
          </div>
        </div>
        {showRatio && (
          <div>
            <p className="font-medium">
              {used}/{limit}
            </p>
          </div>
        )}
      </div>
    );
  }
  export default UsageBar;