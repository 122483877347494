import React, { useState, useEffect, useRef } from 'react';
import { getRequest, postRequest, uploadRequest } from './../utils.js';
import { Button } from './../views/styles';
import styled from 'styled-components';

const Backdrop = styled.div`
  position: fixed;
  background-color: black;
  opacity: 0.85;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 998;
`

const Modal = styled.div`
position: fixed;
top: 50vh;
left: 50vw;
transform: translateY(-50%) translateX(-50%);
z-index: 999;
background-color: white;
border-radius: 16px;
width: 500px;
height: auto;
padding: 32px;

.disable{
  opacity: 0.2;
  pointer-events: none;
}

h1{
  font-size: 16px;
}

hr{
  border: none;
  width: 100%;
  display: block;
  margin: 24px 0;
  background-color: var(--light-grey);
  height: 2px;
}
h2{
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 16px;
}

.actions{
  display: flex;
  justify-content: space-between;
  padding-top: 32px;
}

.full{
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.2;
  border-radius: 16px;
  object-fit: cover;
}

.row{
  display: flex;
  flex-direction: row;
}

.group{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 16px;
  &.pic{
    max-width: 70px;
  }

  label{
      margin-bottom: 8px;
  }
}
`

const Label = styled.label`
  display: block;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const InputField = styled.input`
  width: 100%;
  padding: 18px 12px;
  margin-bottom: 20px;
  border-radius: 16px;
  border: 1px solid var(--grey);
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
`;

const FileUpload = styled.div`
  width: 70px;
  border: 1px dashed #4b4b4d;
  border-radius: 16px;
  text-align: center;
  padding: 8px 12px;
  box-sizing: border-box;
  position: relative;
  color: white;
  p{
    padding: 16px 24px;
    color: white;
    margin: 0;
  }

  .icon{
    width: 32px;
    max-width: 32px;
    height: 32px;
    z-index: 4;
    position: relative;
  }
  span{
    font-size: 11px;
    position: absolute;
    left: 0;
    bottom: -24px;
    opacity: 0.2;
  }
  &:hover {
    border-color: #8e8e93;
    cursor: pointer;
  }

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }

`;

const User = ({handleProfile, cancelProfile, closeModal}) => {
  const [user, setUser] = useState({ id: '', name: '', picture: '', email: '', verified: '' });

  const [name, setName] = useState('');
  const [teamName, setTeamName] = useState('');
  const [teamDesc, setTeamDesc] = useState('');

  const [file, setFile] = useState(null);
  const [teamFile, setTeamFile] = useState(null);

  const handleFileChange = async (e) => {
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    const { id, filename } = await uploadRequest('/upload', formData);
    setFile({id: id, filename: filename})
  };

  const handleTeamFileChange = async (e) => {
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

    if (file.size > 10 * 1024 * 1024) {
        alert('File size exceeds 10MB. Please choose a smaller file.');
        return;
    }

    const formData = new FormData();
    formData.append('file', file);
    const { id, filename } = await uploadRequest('/upload', formData);
    setTeamFile({id: id, filename: filename})
  };

  useEffect(() => {
    const getUser = async () => {
      const data = await getRequest('/user');
      setUser(data);
      setName(data.name);
      setTeamName(data.team_name);
      setTeamDesc(data.team_desc);
     };
    getUser();
  }, []);

  const saveProfile = async () => {
    await postRequest(`/edit_user/${user.id}`, JSON.stringify({
      name: name,
      picture: file ? `${process.env.REACT_APP_API_URL}/profile/${file.id}` : user.picture,
    }));

    if(user.team){
      await postRequest(`/edit_team/${user.team}`, JSON.stringify({
        name: teamName,
        description: teamDesc,
        logo_url: teamFile ? `${process.env.REACT_APP_API_URL}/profile/${teamFile.id}` : user.team_logo,
      }));
    }

    handleProfile();
  }

 const handleName = (e) => { setName(e.target.value); };
 const handleTeamName = (e) => { setTeamName(e.target.value); };
 const handleTeamDesc = (e) => { setTeamDesc(e.target.value); };

  return (
    <div>
      <Backdrop onClick={cancelProfile}> </Backdrop>

      <Modal>
        <h2>Logout</h2>
        {/* <div className="row"> */}

        {/* <div className="group pic">

          <Label> Avatar </Label>
          <FileUpload>
            <input
              type="file"
              id="fileUpload"
              accept="image/*"
              onChange={handleFileChange}
            />

            {(user || file) && <img className="full" src={file ? `${process.env.REACT_APP_API_URL}/profile/${file.id}` : user.picture }/>}
            <img className="icon" src="https://storage.googleapis.com/prequel-public/Frame%20446.png" alt="upload"/>
          </FileUpload>
        </div>

          <div className="group">
            <Label htmlFor="name">Full Name</Label>
            <InputField
              type="text"
              id="name"
              value={name}
              onChange={handleName}
              placeholder="Enter name"
            />
          </div>
        </div> */}

{/* 
        <div className={user.team ? 'enable' : 'disable' }>
          <hr/>
          <h2>Edit company</h2>
          <div className="row">
            <div className="group pic">
              <Label> Logo </Label>
              <FileUpload>
                <input
                  type="file"
                  disabled={!user.team}
                  accept="image/*"
                  id="teamFileUpload"
                  onChange={handleTeamFileChange}
                />

                {(user || teamFile) && user.team && <img className="full" src={teamFile ? `${process.env.REACT_APP_API_URL}/profile/${teamFile.id}` : user.team_logo }/>}
                <img className="icon" src="https://storage.googleapis.com/prequel-public/Frame%20446.png" alt="upload"/>
              </FileUpload>

            </div>

            <div className="group">
              <Label htmlFor="cname">Company Name</Label>
              <InputField
                type="text"
                disabled={!user.team}
                id="cname"
                value={teamName}
                onChange={handleTeamName}
                placeholder="Enter name"
              />

            </div>
          </div>
          <Label htmlFor="desc">One line statement about your project</Label>

          <InputField
            style={{height: '100px'}}
            type="text"
            disabled={!user.team}
            id="desc"
            value={teamDesc}
            onChange={handleTeamDesc}
            placeholder="Write something.."
        />
        </div> */}

        <div className="actions">
          {/* <Button className="main" onClick={() => saveProfile()}>Save profile</Button> */}
          <Button className="main" onClick={closeModal} style={{ backgroundColor: '#000000', color: '#ffffff'}}>Stay</Button>
          <Button className="main danger" onClick={() => {window.location.href = process.env.REACT_APP_API_URL + '/logout'}} style={{ backgroundColor: '#5046E4', color: '#ffffff'}}>Logout</Button>
        </div>
      </Modal>
    </div>
  );
};

export default User;

