import React from 'react';

function PageLayout({ title, children }) {
    return (
        <div className="mx-auto flex flex-col gap-4">
            <h1 className="text-2xl font-bold leading-[1.1] tracking-tighter text-center text-black mt-3">
                {title}
            </h1>
            <main className="p-4 flex flex-col justify-between items-center">
                {children}
            </main>
        </div>
    );
}

export default PageLayout;