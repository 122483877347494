
function PackageHeader({
    className = '',
    alignment = 'left',
}) {
    return (
        <div
            className={`py-12 text-${alignment} ${className}`}
            style={{
                marginLeft: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
            }}
        >
            <div className="m-auto w-full max-w-5xl">
                <h1
                    className="text-center text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl"
                    style={{ whiteSpace: 'normal', fontSize: '30px' }}
                >
                    <span>
                        {/* <span className="bg-gradient-to-r from-gray-800 via-gray-500 to-gray-800  bg-clip-text font-display text-4xl font-extrabold leading-tight text-transparent sm:text-5xl sm:leading-tight"> */}
                        Choose your package
                    </span>
                </h1>
                <p className="mt-8 text-center text-lg leading-8 text-gray-600">
                    Pricing meet your needs as you grow
                </p>
            </div>
        </div>
    );
}
export default PackageHeader;