import React from 'react';
import PulsingIcon from '@/components/ui/PulsingIcon';
import GradientText from '@/components/ui/GradientText';
import Fredda from '../../public/fredda-2.png';

import Icon from '@/components/Icon.js';

import styled from 'styled-components';

const FreddaCallingContainer = styled.div`
    display: inline-flex;
    padding: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 35px;
    width: 331px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border-radius: 16px;
    min-height: 75vh;
    // background-color: rgb(90, 115, 142, 0.3);
    box-shadow: 0px 10px 11.2px 0px rgba(0, 0, 0, 0.10);
    backdrop-filter: blur(2px);
    // background: linear-gradient(
    //     to bottom,
    //     rgba(0, 0, 0, 0.7) 0%,
    //     rgba(0, 0, 0, 0.5) 50%,
    //     rgba(0, 0, 0, 0.3) 100%
    // )
    background: linear-gradient(
        to bottom,
        rgba(50, 20, 0, 0.8) 0%,
        rgba(35, 10, 0, 0.6) 50%,
        rgba(20, 0, 0, 0.4) 100%
    )
`;

const StyledImage = styled.img`
    width: 180px !important;
    border-radius: 50%;
    margin-top: 10px;

`;

const FreddaHeader = styled.div`
    color: #FFF;
    font-size: 24px;
    font-weight: 700;
`;

const StyledAnswerButton = styled.button`
    display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 22px;
    background: rgba(57, 204, 84, 0.8);
    width: 241px;
`;
const StyledHangupButton = styled.button`
    display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 22px;
    background: rgba(255, 69, 57, 0.4);
    width: 241px;
`;
const StyledCallText = styled.div`
   color: var(--White-800, rgba(255, 255, 255, 1));
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    cursor: pointer;
`;
function FreddaCalling({ initCall, initChat, endCall, vcall }) {
    return (
        <FreddaCallingContainer>
            <div className="grid gap-4 justify-center items-center justify-items-center">
                <FreddaHeader>Fredda</FreddaHeader>
                <GradientText text="Audio call" />
            </div>
            
            <StyledImage src={Fredda} alt="Sal" style={{ width: '60px' }} />

            {vcall === null ?

                <StyledAnswerButton onClick={initCall}>
                    <PulsingIcon name="answer" />
                </StyledAnswerButton>

                :
                <StyledHangupButton onClick={endCall}>
                    <Icon name="hangup" />
                </StyledHangupButton>
            }
            <StyledCallText onClick={initChat}>Chat via message instead</StyledCallText>

        </FreddaCallingContainer>

    );
}

export default FreddaCalling;