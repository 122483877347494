import React from 'react';
import { ChatContainer, Chat, Loader } from '@/views/styles';
import styled from 'styled-components';
import Icon from '@/components/Icon';
import Message from '@/components/Message';
import ChatInput from '@/components/chat/ChatInput';
import { useNavigate } from 'react-router-dom';

const StyledLoaderChat = styled.span`
  width: 30px;
  height: 30px;
  border: 5px solid var(--dark);
  border-bottom-color: #3A4E69;
`
const StyledButton = styled.button`
    background-color: #6366F1;
    color: white;
`
const ChatLayoutPage = ({
    lesson,
    user,
    chatHistory,
    messageLoading,
    messageRef,
    inputValue,
    textareaRef,
    hiddenFileInput,
    handleInputChange,
    handleKeyDown,
    sendMessage,
    setMessageLoading,
    sendFile,
    askModel,
    isHalfScreen = false,
    children,
    isContentPage,
}) => {
    const navigate = useNavigate();

    return (
        <>
            <ChatContainer className="chat-container">
                <div className="flex justify-end">
                    <StyledButton
                        className="bg-indigo-300 hover:bg-indigo-400 text-white-800 font-bold py-2 px-4 rounded inline-flex items-center mr-5"
                        onClick={() => {
                            navigate('/call');
                        }}
                    >
                        <Icon name="answer"></Icon>
                        <span>Call your coach Fredda</span>
                    </StyledButton>
                </div>

                <Chat className={isHalfScreen ? "half" : "move"}>
                    {chatHistory.map((message, index) => {
                        return (
                            <React.Fragment key={index + message.role}>
                                <Message
                                    message={message}
                                    index={index}
                                    user={user}
                                    messageRef={messageRef}
                                    key={index + message.role}
                                />
                            </React.Fragment>
                        )
                    })}
                    {messageLoading && <Loader> <StyledLoaderChat className="loader"></StyledLoaderChat> </Loader>}
                    {children}
                </Chat>
                <ChatInput
                    lesson={lesson}
                    hiddenFileInput={hiddenFileInput}
                    textareaRef={textareaRef}
                    inputValue={inputValue}
                    handleInputChange={handleInputChange}
                    handleKeyDown={handleKeyDown}
                    sendMessage={sendMessage}
                    messageLoading={messageLoading}
                    setMessageLoading={setMessageLoading}
                    sendFile={sendFile}
                    askModel={askModel}
                    isHalfScreen={isHalfScreen}
                    isContentPage={isContentPage}
                />

            </ChatContainer>
            <p className="text-zinc-800 text-center -translate-x-1/2 left-1/2 " style={{ marginLeft: "10px", fontSize: "14px", position: "absolute",
    bottom: "3px"}}>
                Fredda can make mistakes. Check important info.

            </p>
        </>
    );
};
export default ChatLayoutPage;