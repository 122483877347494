import React, { useState, useEffect } from 'react';
import  { postRequest, getRequest, PROGRAMS } from '@/utils.js';
import  { AdminView, AdminHeader, AdminSidebarForm, Button } from '@/views/styles.js';
import Nav from '@/components/admin/Nav.js';
import Icon from '@/components/Icon.js';
import styled from 'styled-components';

const Content = styled.div`
height: 100vh;
flex-grow: 1;
overflow-y: scroll;
`

const Page = styled.div`
display: flex;
flex-direction: row;
`

const ResourceBlock = styled.div`
display: flex;
flex-grow: 1;
flex-direction: column;
border: 1px solid var(--admin);
border-radius: 8px;
margin: 32px;
height: calc(100vh - 200px);
overflow-y: scroll;
h2{
  padding: 12px;
  font-weight: 600;
  border-bottom: 1px solid var(--admin);
}
`

const ResourceGroup = styled.div`
display: flex;
flex-direction: column;
h2{
  padding: 16px;
  font-weight: 600;
  border-bottom: 1px solid var(--admin);
  border-top: 1px solid var(--admin);
}

&:first-child{
  h2{
    border-top: none;
  }
}
&:last-child{ border-bottom: none }
`

const Resource = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
padding: 16px;
border-bottom: 1px solid var(--admin);
transition-duration: 0.3s;
cursor: pointer;

&:hover{
  background-color: #F1F5F9;
}

h3{
  font-weight: 500;
  margin-right: 16px;
  min-width: 350px;
  max-width: 350px;
}
p{
  color: black;
  width: 125px;
}
>div{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
img{
  border-radius: 4px;
  width: 75px;
  height: 50px;
  object-fit: cover;
  margin-right: 16px;
}
&:last-child{ border-bottom: none }
`

const CourseForm = ({ course, onChange }) => (
  <div className="group">
    <label>Course</label>
    <div>
      <input type="radio" name="course" value="none" checked={!course || !course.type} onChange={() => onChange(null)} /> None
      <input type="radio" name="course" value="video" checked={course && course.type === 'video'} onChange={() => onChange({ type: 'video' })} /> Video
      <input type="radio" name="course" value="slides" checked={course && course.type === 'slides'} onChange={() => onChange({ type: 'slides' })} /> Slides
      <input type="radio" name="course" value="img" checked={course && course.type === 'img'} onChange={() => onChange({ type: 'img' })} /> Image
    </div>
    {course && (
      <div className="row">
        {course.type === 'img' && (
          <img src={course.asset || 'https://via.placeholder.com/150'} alt="img" />
        )}
        <div className="group">
          <label htmlFor="asset">Configure {course.type} Asset</label>
          <input type="text" name="asset" id="asset" placeholder={`url to ${course.type}`} value={course.asset || ''} onChange={(e) => onChange({ ...course, asset: e.target.value })} />
        </div>
        {course.type === 'video' && (
          <div className="group">
            <label htmlFor="len">Length</label>
            <input type="text" name="len" id="len" placeholder="3:02" value={course.len || ''} onChange={(e) => onChange({ ...course, len: e.target.value })} />
          </div>
        )}
        {course.type === 'slides' && (
          <div className="group">
            <label htmlFor="len">Page Range</label>
            <input type="text" name="len" id="len" placeholder="1-3" value={course.len || ''} onChange={(e) => onChange({ ...course, len: e.target.value })} />
          </div>
        )}
      </div>
    )}
    {course && course.type === 'video' && (
      <div className="row">
        <img src={course.preview || 'https://via.placeholder.com/150'} alt="img" />
        <div className="group img-group">
          <label htmlFor="preview">Video Thumbnail</label>
          <input type="text" name="preview" id="preview" placeholder="upload image" value={course.preview || ''} onChange={(e) => onChange({ ...course, preview: e.target.value })} />
        </div>
      </div>
    )}
  </div>
);

const StepForm = ({ editing, step, index, onChange, onRemove }) => (
  <div className="step">
    <div className="badge"> {index + 1} </div>
    <div className="group">
      <label htmlFor={`content-${index}`}>Content</label>
      <textarea name={`content-${index}`} id={`content-${index}`} placeholder="Content" value={step.content} onChange={(e) => onChange({ ...step, content: e.target.value })} />
    </div>

    { editing.type.includes('pre') &&
      <input type="text" placeholder="Prompt" value={step.prompt} onChange={(e) => { onChange({ ...step, prompt: e.target.value }); }} />
    }
    { !editing.type.includes('pre') &&
      <div className="group">
        {(step.prompts || []).map((prompt, promptIndex) => (
          <input key={promptIndex} type="text" placeholder="Prompt" value={prompt} onChange={(e) => {
            const newPrompts = [...(step.prompts || [])];
            newPrompts[promptIndex] = e.target.value;
            onChange({ ...step, prompts: newPrompts });
          }} />
        ))}
        <Button className="primary admin" type="button" onClick={() => onChange({ ...step, prompts: [...(step.prompts || []), ''] })}> Add Prompt</Button>
      </div>
    }

    <CourseForm course={step.course} onChange={(course) => onChange({ ...step, course })} />

    {step.collect && (
      <div className="row">

        <div className="group">
          <label htmlFor={`type-${index}`}>Type</label>
          <select name={`type-${index}`} id={`type-${index}`} value={step.collect.type} onChange={(e) => onChange({ ...step, collect: { ...step.collect, type: e.target.value } })}>
            <option value="text">Text</option>
            <option value="number">Number</option>
            <option value="submission">Submission</option>
          </select>
        </div>
        {step.collect.type !== 'submission' && (
          <>
            <div className="group">
              <label htmlFor={`name-${index}`}>Name</label>
              <input type="text" name={`name-${index}`} id={`name-${index}`} placeholder="Name" value={step.collect.name} onChange={(e) => onChange({ ...step, collect: { ...step.collect, name: e.target.value } })} />
            </div>
            <div className="group">
              <label htmlFor={`count-${index}`}>Count</label>
              <input type="number" name={`count-${index}`} id={`count-${index}`} placeholder="3" value={step.collect.count} onChange={(e) => onChange({ ...step, collect: { ...step.collect, count: parseInt(e.target.value ) } })} />
            </div>
          </>
        )}
      </div>
    )}
    <Button className="admin secondary" type="button" onClick={() => onChange({ ...step, collect: step.collect ? null : { type: '', name: '', count: '' } })}>
      {step.collect ? 'Remove Input Collection' : 'Collect Input'}
    </Button>
    <Button className="admin danger" type="button" onClick={onRemove}>Remove Step</Button>
  </div>
);
const Resources = () => {
const [lessons, setLessons] = useState([])
const [editing, setEditing] = useState(null)
const [user, setUser] = useState({permissions: []})
const [cohorts, setCohorts] = useState([])

const loadLessons = async () => {
  const u = await getRequest('/user')
  setUser(u)
  const data = await getRequest('/admin/lessons')
  const d = await getRequest('/admin/cohorts')
  setCohorts(d)

  const types = [...new Set(data.map(lesson => (lesson.type || lesson.program)))];
  const organizedLessons = types.map(type => {
    return {
      type,
      lessons: data.filter(resource => (resource.type || resource.program) === type),
    };
  });
  setLessons(organizedLessons);
}

useEffect(() => {
  loadLessons()
}, [])

const deleteResource = async (id) => {
  if (window.confirm("Are you sure you want to delete this resource?")) {
    await getRequest('/admin/lesson/delete?id=' + id);
    setEditing(null);
    loadLessons();
  }
};

const togglePublish = async (id) => {
  if (window.confirm("Are you sure you want to publish this resource? Please save any edit before publishing.")) {
    await getRequest('/admin/lesson/publish?id=' + id);
    loadLessons();
    setEditing({...editing, published: !editing.published});
  }
};

const save = async (e) => {
  if (window.confirm("Are you sure you want to save these changes?")) {
    await postRequest('/admin/lessons', JSON.stringify(e));
    loadLessons();
  }
};

const createResource = async (e) => {
  await postRequest('/admin/lesson/add', JSON.stringify(e));
  loadLessons();
  setEditing(null);
};

const addMode = () => {
  setEditing(null);
  setTimeout(() => { setEditing({}) }, 300)
}

const handleStepChange = (index, newStep) => {
    const newSteps = [...editing.steps];
    newSteps[index] = newStep;
    setEditing({ ...editing, steps: newSteps });
  };

  const handleRemoveStep = (index) => {
    const newSteps = editing.steps.filter((_, i) => i !== index);
    setEditing({ ...editing, steps: newSteps });
  };
return (
  <AdminView>
    <Nav />

    { (!user || !user.permissions.includes('pd')) && <div class="max-center">
        <Icon name="close" size={50}></Icon>
        <p> You do not have permission to view this page. </p>
      </div> }

    { user && user.permissions.includes('pd') &&
      <Content>
      <AdminHeader>
        <h1>Lessons</h1>
        <Button className="admin primary" onClick={() => { addMode() }}> <Icon name="add" size={10}></Icon> Add Lesson</Button>
      </AdminHeader>

      <Page>
        <ResourceBlock>
          {lessons.map((lessonGroup) => {
            return (
              <ResourceGroup key={lessonGroup.type}>
                <h2>{lessonGroup.type}</h2>
                { lessonGroup.lessons.map((lesson) => {
                  return (
                    <Resource onClick={() => {setEditing(lesson)}}>
                      <div>
                        <img src={lesson.img} alt=""/>
                        <h3>{lesson.name}</h3>
                        <p>{lesson.published ? 'Published' : 'Draft' }</p>
                      </div>

                    </Resource>
                  )
                })}
              </ResourceGroup>
            )
          })}
        </ResourceBlock>
      </Page>
    </Content>
    }

    {editing !== null &&
      <AdminSidebarForm>
        <AdminHeader className="sec-header">
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <div onClick={() => { setEditing(null)} }>
              <Icon name="close" size={8} ></Icon>
            </div>
            <h2>Edit lesson</h2>
          </div>
          <div>
            {!editing._id &&
              <Button className="admin primary" onClick={() => { createResource(editing) }}> Create Lesson </Button>
            }
            {editing._id &&
              <>
                <Button className="admin danger" onClick={() => { deleteResource(editing.id) }}> Delete </Button>
                <Button className="admin secondary" onClick={() => { save(editing) } }> Save </Button>
                <Button className="admin primary" onClick={() => { togglePublish(editing.id) } }> {editing.published ? 'Unpublish' : 'Publish' } </Button>
              </>
            }
          </div>
        </AdminHeader>

        <form>
          <div className="row">
            <img src={editing.img || 'https://via.placeholder.com/150' } alt="img" />
            <div className="group img-group">
              <label htmlFor="img">Image Link</label>
              <input type="text" name="img" id="img" placeholder="upload image" value={editing.img} onChange={(e) => {setEditing({...editing, img: e.target.value}) }} />
            </div>
          </div>

          <div className="row">
            <div className="group">
              <label htmlFor="name">Title</label>
              <input
                type="text" name="name" id="name" placeholder="Resource Name"
                value={editing.name}
                onChange={(e) => {setEditing({...editing, name: e.target.value}) }}/>
            </div>

            <div className="group">
              <label htmlFor="slug">URL Slug</label>
              <input type="text" name="slug" id="slug" placeholder="resource-url-name"
                value={editing.id}
                onChange={(e) => {setEditing({...editing, id: e.target.value}) }}
              />
            </div>
          </div>

          <div className="row">
            <div className="group">
              <label htmlFor="program">Program</label>
              <select value={editing.program} onChange={(e) => { setEditing({...editing, program: e.target.value, type: e.target.value === 'alpha-x' ? 'stage-1' : 'pre' }); }}>
                {!editing._id && <option value="" selected default disabled>select program</option> }
                {PROGRAMS.map(program => <option value={program} key={program}>{program}</option>)}
              </select>
            </div>

              {editing.program === 'alpha-x' ?
                <div className="group">
                  <label htmlFor="type">Type</label>
                  <select value={editing.type} onChange={(e) => { setEditing({...editing, type: e.target.value});}}>
                    {!editing._id && <option value="" selected default disabled>select type</option> }
                    <option value="stage-pre">Pre Stage</option>
                    <option value="stage-1">Stage 1</option>
                    <option value="stage-2">Stage 2</option>
                    <option value="stage-3">Stage 3</option>
                    <option value="stage-4">Stage 4</option>
                    <option value="stage-5">Stage 5</option>
                  </select>
                </div> :
                <div className="group">
                  <label htmlFor="type">Type</label>
                  <select value={editing.type} onChange={(e) => { setEditing({...editing, type: e.target.value}); }}>
                    {!editing._id && <option value="" selected default disabled>select type</option> }
                    <option value="pre">Pre Camp</option>
                    <option value="camp">During Camp</option>
                  </select>
                </div>
              }

            <div className="group">
              <label htmlFor="order">Order</label>
              <input type="number" name="order" id="order" placeholder="Lesson Number"
                value={editing.lessonNumber}
                onChange={(e) => {setEditing({...editing, lessonNumber: parseInt(e.target.value) }) }} />
            </div>
          </div>

            <div className="row">
              <div className="group">
                <label htmlFor="emojis">Celebration Emojis (comma separated)</label>
                <input type="text" name="emojis" id="emojis" placeholder="🎉,🎊,🚀,📊" value={editing.emojis} onChange={(e) => {setEditing({...editing, emojis: e.target.value}) }}
                />
              </div>
            </div>

            <div className="row">
              <div className="group">
                <label htmlFor="context">Context</label>
                <textarea name="context" id="context" placeholder="LLM prompt" value={editing.context} onChange={(e) => {setEditing({...editing, context: e.target.value}) }}
                />
              </div>
            </div>

            <div className="combo">
              <div className="row">
                <label> Due Dates </label>
              </div>
              <div className="row">
                {cohorts.filter((c) => c.program === editing.program).map(cohort => (
                  <div className="group">
                    <label htmlFor="due">Cohort #{cohort.session} </label>
                    <input type="date" name="order" id="order" placeholder="Lesson Number" value={editing.due ? editing.due[cohort.session] : ''} onChange={(e) => {setEditing({...editing, due: {...editing.due, [cohort.session]: e.target.value} }) }} />
                  </div>
                ))}
              </div>
            </div>

            <div className="combo">
              <div className="row">
                <div className="group">
                  <label>
                    <input type="checkbox" checked={!!editing.submission} disabled={editing.type.includes('pre')} onChange={(e) => setEditing({...editing, submission: e.target.checked ? {} : null})} />
                    Enable Submission
                  </label>
                </div>
              </div>

              {editing.submission && (
                <div>
                  <div className="row">
                    <div className="group">
                      <label>Submission Type</label>
                      <div>
                        <input type="radio" value="text" checked={!editing.submission.type || editing.submission.type === 'text'} onChange={(e) => setEditing({...editing, submission: {...editing.submission, type: 'text'} })} /> Text
                        <input type="radio" value="video" checked={editing.submission.type === 'video'} onChange={(e) => setEditing({...editing, submission: {...editing.submission, type: 'video'} })} /> Video
                        <input type="radio" value="audio" checked={editing.submission.type === 'audio'} onChange={(e) => setEditing({...editing, submission: {...editing.submission, type: 'audio'} })} /> Audio
                        <input type="radio" value="doc" checked={editing.submission.type === 'doc'} onChange={(e) => setEditing({...editing, submission: {...editing.submission, type: 'doc'} })} /> Doc
                      </div>

                    </div>
                  </div>
                  <div className="row">
                    <div className="group">
                      <label htmlFor="item">Item</label>
                      <input type="text" name="item" id="item" placeholder="Item" value={editing.submission.item || ''} onChange={(e) => setEditing({...editing, submission: {...editing.submission, item: e.target.value}})} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="group">
                      <label htmlFor="prompt">Prompt</label>
                      <input type="text" name="prompt" id="prompt" placeholder="Prompt" value={editing.submission.prompt || ''} onChange={(e) => setEditing({...editing, submission: {...editing.submission, prompt: e.target.value}})} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="group">
                      <label htmlFor="cta">CTA</label>
                      <input type="text" name="cta" id="cta" placeholder="Call to Action" value={editing.submission.cta || ''} onChange={(e) => setEditing({...editing, submission: {...editing.submission, cta: e.target.value}})} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="group">
                      <label htmlFor="criteria">Criteria</label>
                      <textarea name="criteria" id="criteria" placeholder="Criteria" value={editing.submission.criteria || ''} onChange={(e) => setEditing({...editing, submission: {...editing.submission, criteria: e.target.value}})} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="group">
                      <label htmlFor="example">Example</label>
                      <textarea name="example" id="example" placeholder="Example" value={editing.submission.example || ''} onChange={(e) => setEditing({...editing, submission: {...editing.submission, example: e.target.value}})} />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="col">
              <label>Steps</label>
              {editing.steps && editing.steps.map((step, index) => (
                <StepForm key={index} editing={editing} step={step} index={index} onChange={(newStep) => handleStepChange(index, newStep)} onRemove={() => handleRemoveStep(index)} />
              ))}
              <Button
                className="success admin"
                style={{ margin: '32px 0' }}
                type="button" onClick={() => setEditing({ ...editing, steps: [...(editing.steps || []), { content: '', prompts: [], course: {}, collect: null }] })}>
                Add Step
              </Button>
            </div>
          </form>
        </AdminSidebarForm>
      }
    </AdminView>
  );
};

export default Resources;
