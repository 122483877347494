import { useLocation } from 'react-router-dom';

export default function Footer() {
  const location = useLocation();

  const isChatPages = location.pathname === '/chat' || location.pathname === '/call' || location.pathname === '/';

  return (
    <>
      <div
        className="space-y-3  bg-slate-100 py-5"
        style={{
          position: "sticky",
          bottom: "0",
          left: "0",
          width: "100%",
        }}
      >
        <div className="block">
          <div className="text-left">

            {isChatPages ?
              <p className="text-zinc-800 text-center" style={{ marginLeft: "10px", fontSize: "14px" }}>
                Fredda can make mistakes. Check important info.

              </p>

              :
              <p
                className="text-zinc-800"
                style={{ marginLeft: "10px", fontSize: "14px" }}
              >
                Need help? contact us at{" "}
                <a
                  href="mailto:contact@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                  className="font-bold underline-offset-2 transition hover:underline"
                >
                  contact@gmail.com{" "}
                </a>
              </p>
            }

          </div>
        </div>
      </div>
    </>
  );
}
