import Footer from '@/components/ui/Footer';
import Navbar from '@/components/Nav'
import { useLocation } from 'react-router-dom';

export default function Layout({ children }) {
    const location = useLocation();
    const isChat = location.pathname === '/chat';

  return (
    <div className="mx-auto flex flex-col space-y-4">
      {/* <header className="sticky top-0 z-40 bg-white"> */}
      {/* <div className="h-16 border-b border-b-slate-200 py-4"> */}
      <Navbar />
      {/* </div> */}
      {/* </header> */}
      <div
        style={{
          minHeight: '85vh', // minus footer
          overflow: 'hidden',
        }}
      >
        <main className="flex w-full flex-1 flex-col overflow-hidden">
          {children}
        </main>
      </div>
      {!isChat && <Footer />}
    </div>
  );
}
