import { useState, useEffect, useRef } from 'react';
import { getRequest, askChatQuestion, gptFeedback, multiModalChat } from '@/utils';
import useLocalStorageState from 'use-local-storage-state';

const useChatLogic = (lessonId, gptFn = undefined) => {
  const [user, setUser] = useState({ id: '', name: '', picture: '', email: '', verified: '' });
  const [chatHistory, setChatHistory] = useState([])
  const [lesson, setLesson] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [messageLoading, setMessageLoading] = useState(false);
  const [activeStep, setActiveStep] = useLocalStorageState('work-step-' + lessonId, { defaultValue: 0 });
  const [existingSub, setExistingSub] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [submissionMode, setSubmissionMode] = useState(false);
  const [dragOver, setDragOver] = useState(false);
  const [chatID, setChatID] = useState(null);
  const chatContainerRef = useRef(null);
  const textareaRef = useRef(null);
  const hiddenFileInput = useRef(null);

  const [collected, setCollected] = useLocalStorageState('prework-collected-' + lessonId, { defaultValue: {} })

  useEffect(() => {
    const getUser = async () => {
      const ud = await getRequest('/user');
      setUser(ud);
      window.user = ud;
    };
    getUser();
  }, []);

  const handleInputChange = (e) => {
    const textarea = e.target;
    if (textarea && textarea.style) {
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    }
    setInputValue(e.target.value);
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      const scrollContainer = chatContainerRef.current;
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    }
  }, [chatHistory]);

  const sendMessage = async (e, force = false) => {
    if (inputValue.trim() === '' && typeof e !== 'string') return;
    const userMessage = typeof e === 'string' && force ? e : inputValue.trim() ;
    const ch = [...chatHistory, { role: 'user', content: userMessage }];
    setChatHistory(ch);
    setMessageLoading(true);
    setInputValue('');

    if (textareaRef && textareaRef.current && textareaRef.current.style) {
      textareaRef.current.style.height = 'auto';
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/chat/question/gpt`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: userMessage,
          user_id: user.id,
          chat_id: chatID,
          context: chatHistory.length > 0 ? JSON.stringify(chatHistory) : undefined,
          is_follow_up_question: !chatID && chatHistory.length === 2,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let accumulatedResponse = '';

      // Add a placeholder for the assistant's message
      setChatHistory(prevHistory => [
        ...prevHistory,
        { role: 'assistant', content: '' }
      ]);

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        accumulatedResponse += chunk;
        // Check if the chunk contains END_OF_STREAM
        if (chunk.includes('END_OF_STREAM')) {
          const [content, metaData] = accumulatedResponse.split('END_OF_STREAM');
          const newChatID = metaData.split(':')[1].trim();
          setChatID(newChatID);
          accumulatedResponse = content; // Remove END_OF_STREAM and chatID from the message
        }

        // Update the last message in the chat history
        setChatHistory(prevHistory => {
          debugger
          const updatedHistory = [...prevHistory];
          updatedHistory[updatedHistory.length - 1].content = accumulatedResponse;
          return updatedHistory;
        });
      }

      // Ensure the final message is set correctly
      setChatHistory(prevHistory => {
        const updatedHistory = [...prevHistory];
        updatedHistory[updatedHistory.length - 1].content = accumulatedResponse;
        return updatedHistory;
      });
    } catch (error) {
      console.error('Error fetching bot response:', error);
    }

    setMessageLoading(false);
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      sendMessage(e);
    }
  };

  const askModel = async (userMessage) => {
    setMessageLoading(true);
    const ch = [...chatHistory, { role: 'user', content: userMessage }]
    setChatHistory(ch)
    gptFeedback(userMessage, lesson.context, (response) => {
      setChatHistory([...chatHistory, { role: 'user', content: userMessage }, { role: 'assistant', content: response }])
    })
    setMessageLoading(false);
  }

  const sendFile = async (e) => {
    e.preventDefault();
    setDragOver && setDragOver(false);
    setUploading(true);

    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

    if (file.size > 1000 * 1024 * 1024) {
      alert('File size exceeds 1GB. Please choose a smaller file.');
      return;
    }

    if (file && !uploading) {
      const message = file.type.includes('video')
        ? "Gimme some time... I'm watching your video."
        : 'reading file...';
      setChatHistory([...chatHistory, { role: 'assistant', content: message }]);

      const data = new FormData();
      data.append('media', file);
      data.append('prompt', lesson.context);

      multiModalChat('chat', data,
        () => { },
        (data) => {
          setSubmissionMode(false);
          setChatHistory([...chatHistory, { role: 'assistant', content: data }]);
        },
        () => {
          setUploading(false);
        },
        (error) => {
          setUploading(false);
        }
      );
      setInputValue('');
    }
  };


  return {
    user,
    setUser,
    chatHistory,
    setChatHistory,
    lesson,
    setLesson,
    inputValue,
    setInputValue,
    isLoading,
    setIsLoading,
    messageLoading,
    setMessageLoading,
    chatContainerRef,
    textareaRef,
    hiddenFileInput,
    handleInputChange,
    sendMessage,
    handleKeyDown,
    askModel,
    activeStep,
    setActiveStep,
    setExistingSub,
    existingSub,
    collected,
    setCollected,
    sendFile,
    submissionMode,
    setSubmissionMode,
    dragOver,
    setDragOver,
  };
};
export default useChatLogic;
