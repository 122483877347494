import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getRequest } from '../utils';
// import ChatLayout from '@/components/chat/ChatLayout';
import { ToastContainer, toast } from 'react-toastify';
import { AccordionItem } from '@/components/ui/AccordionItem';

import PageLayout from '@/components/ui/PageLayout';
import AIAnswer from '@/components/ui/AIAnswer';
import Loading from '@/components/ui/Loading';
import { useUser } from '@/hooks/useUser';

const MyQuestions = () => {
    const [myQuestions, setMyQuestions] = useState();
    const [loadingMyQuestions, setLoadingMyQuestions] = useState();
    const { user } = useUser();

    const loadMyQuestions = async () => {
        setLoadingMyQuestions(true);
        const requestData = { user_id: window.user?.id };
        const data = await getRequest('/user/questions', JSON.stringify(requestData));
        if (data?.error) {
            toast.error("Something went wrong, please try again later.");
        } else {
            setMyQuestions(data.questions);
        }
        setLoadingMyQuestions(false);
    };

    useEffect(() => {
        loadMyQuestions();
    }, []);

    // useEffect(() => {
    //     if (!user.id) {
    //         window.location.href = `${process.env.REACT_APP_API_URL}/login`
    //     }
    // }, [user?.id]);

    return (
        <>
            <PageLayout
                title="My Questions"
                subTitle="Take a look at your search history"
            >
                <div className="text-center">
                    <div className="relative">
                        <p>Take a look at your search history

                        </p>
                    </div>
                </div>
            </PageLayout>

            <div
                style={{ backgroundColor: "#F2F2F2" }}
                className="transition-opacity ease-in duration-700 opacity-100"
            >
                <div
                    style={{
                        backgroundColor: "white",
                        margin: "30px",
                        padding: "20px",
                        borderRadius: "18px",
                    }}
                >
                    {loadingMyQuestions ? <Loading text="Loading questions..." />
                        :
                        <>
                            {myQuestions && myQuestions.length > 0 ? myQuestions?.map((question, key) => {
                                return (
                                    <>

                                        <AccordionItem key={question?.id}
                                            question={question.content}
                                            count={key + 1}
                                            answer=""
                                            id={question.id}
                                        >
                                            <AIAnswer
                                                answer={question.answer}
                                                answerID={question.id}
                                                question={question.content}
                                            />
                                            {/** List of documents TODO */}
                                        </AccordionItem>
                                    </>
                                )
                            }) :
                                <p>This account does not contain any questions yet.</p>
                            }
                        </>}

                </div>
            </div>
            <ToastContainer />
        </>
    );
};

export default MyQuestions;