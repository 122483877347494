import React, { useState, useCallback, useEffect } from 'react';

import Icon from '@/components/Icon.js';
import useChatLogic from '@/hooks/useChatLogic';
import Vapi from "@vapi-ai/web";
import endcall from '@/public/endcall.mp3';
import startcall from '@/public/startcall.mp3';
import FreddaCalling from '@/components/calls/FreddaCalling';
import { useNavigate } from 'react-router-dom';
import { usePackages } from '@/hooks/usePackages';
import OutOfCreditMessage from '@/components/packages/OutOfCreditMessage';
import { useUser } from '@/hooks/useUser';
import PageLayout from '@/components/ui/PageLayout';

const endCallSound = new Audio(endcall);
const startCallSound = new Audio(startcall);

function extractKeyNumber(str) {
    const match = str.match(/\d+/);
    return match ? parseInt(match[0], 10) : null;
}

const Call = () => {
    const [vcall, setVcall] = useState(null)
    const { callHistory, setCallHistory } = useChatLogic();
    const navigate = useNavigate();
    const { packages, loadingPackages } = usePackages();
    const { user, loading: isLoadingUser } = useUser();

    const {
        chatHistory,
        setChatHistory,
        inputValue,
    } = useChatLogic();


    const endCall = () => {
        vcall.stop();
        setVcall(null);
        endCallSound.play();
        vcall.on('message', (m) => { });
    }

    const updateCall = useCallback((message) => {
        if (message.type === 'conversation-update') {
            setCallHistory(message.conversation)
        }
    }, [])

    const initCall = () => {
        console.log('403cd9c7-e07f-48d0-9aea-a2197bcfedbd');
        const vapi = new Vapi('17a0d183-ff94-41fb-be2d-314ea0d6374e');
        setVcall(vapi);
        const assistantOverrides = {
            transcriber: {
                provider: "deepgram",
                model: "nova-2",
                language: "en-US",
            },
            recordingEnabled: false,

            // variableValues: {
            //     goal: team.description,
            //     memo: team.memo,
            //     tasks: tasks.filter((t) => t.status === 'Pending').map((t) => t.stage_name),
            //     activeTask: currentTask,
            // },
        };

        console.log(process.env.VAPI_ASSISTANT_ID);
        vapi.start("8f8341f1-83f4-4b41-8c94-5105f8b1b047", assistantOverrides);
        vapi.on('message', (m) => { updateCall(m) });
        startCallSound.play();
    }

    const initChat = () => {
        navigate('/chat');
    }

    useEffect(() => {
        // Cleanup function to end the call when component unmounts
        return () => {
            if (vcall) {
                endCall();
            }
        };
    }, [vcall]);


    // useEffect(() => {
    //     if (!user.id) {
    //       window.location.href = `${process.env.REACT_APP_API_URL}/login`
    //     }
    //   }, [user?.id]);
      
    // if (loading) {
    //     return <Loader2 />
    // }
    return (
        <>
            {vcall === null && chatHistory.length > 0 &&
                <div className="header">Your Coach
                    <button onClick={() => {
                        setChatHistory([])
                        initCall()
                    }}
                    >
                        <Icon name="answer"></Icon>
                        Call Fredda
                    </button>
                </div>
            }


            {!isLoadingUser && !loadingPackages ? (
                !user?.calls_left || user?.calls_left <= 0 ? (
                    <PageLayout>
                        <OutOfCreditMessage packages={packages} user={user} />
                    </PageLayout>
                ) : (
                    chatHistory.length === 0 && (
                        <FreddaCalling
                            initCall={initCall}
                            endCall={endCall}
                            vcall={vcall}
                            initChat={initChat}
                        />
                    )
                )
            ) : null}
        </>
    );
};

export default Call;
