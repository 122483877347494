import React from 'react'
import PackageList from '@/components/packages/packageList';
import { stripePromise } from '@/util/helper';
import {
  Elements,
} from '@stripe/react-stripe-js';

const OutOfCreditMessage = ({ packages, user }) => (
    <div className="mx-auto flex mt-8 flex-col gap-4 pricing-div-container">
      <div className="mb-4 items-baseline justify-between">
        <h2
          className="font-display text-3xl font-bold tracking-wide"
          style={{
            fontSize: "20px",
          }}
        >
          You have run out of credit.{" "}
          <span className="bg-gradient-to-r from-gray-800 via-gray-500 to-gray-800 bg-clip-text text-transparent">
            Please select one of our packages
          </span>{" "}
          to continue.
        </h2>
      </div>
      <Elements stripe={stripePromise}>
        <PackageList packages={packages} user={user} />
      </Elements>
    </div>
  );
  export default OutOfCreditMessage;